export const APPLICATION_CONTAINER_ID = 'AppContainer';

// Form field constants
export const FIELD_CHECKBOX = 'FIELD_CHECKBOX';
export const FIELD_RADIOS = 'FIELD_RADIO';
export const FIELD_SELECT = 'FIELD_SELECT';
export const FIELD_DROPDOWN = 'FIELD_DROPDOWN';
export const FIELD_TEXT = 'FIELD_TEXT';
export const FIELD_TEXTAREA = 'FIELD_TEXTAREA';
export const FIELD_SWITCH = 'FIELD_SWITCH';
export const LABEL_OPTIN = `I would like to be contacted by Audi and Audi Dealers with occasional communications on products, offers, news and events.`;

export const MODELS_DROPDOWN = [
  {
    label: "e-tron",
    value: "e-tron"
  },
  {
    label: "e-tron Sportback",
    value: "e-tron-sportback"
  }
];
export const MODELS_SEARCH = {
  "e-tron": "(model/modelcode eq 'GENBBE/20') or (model/modelcode eq 'GENBAE/20') or (model/modelcode eq 'GENBAE/20LE')",
  "e-tron-sportback": "(model/modelcode eq 'GEACBE/20') or (model/modelcode eq 'GEACAE/20') or (model/modelcode eq 'GEACAE/20LE')"
};

export const OPTS_STATES = [
  {value: 'ACT', label: 'ACT'},
  {value: 'NSW', label: 'New South Wales'},
  {value: 'NT', label: 'Northern Territory'},
  {value: 'QLD', label: 'Queensland'},
  {value: 'SA', label: 'South Australia'},
  {value: 'TAS', label: 'Tasmania'},
  {value: 'VIC', label: 'Victoria'},
  {value: 'WA', label: 'Western Australia'},
];

export const EXP_MONTHS = [
  {value: 1, label: '01'},
  {value: 2, label: '02'},
  {value: 3, label: '03'},
  {value: 4, label: '04'},
  {value: 5, label: '05'},
  {value: 6, label: '06'},
  {value: 7, label: '07'},
  {value: 8, label: '08'},
  {value: 9, label: '09'},
  {value: 10, label: '10'},
  {value: 11, label: '11'},
  {value: 12, label: '12'},
];
export const EXP_YEARS = [
  {value: 2020, label: '2020'},
  {value: 2021, label: '2021'},
  {value: 2022, label: '2022'},
  {value: 2023, label: '2023'},
  {value: 2024, label: '2024'},
  {value: 2025, label: '2025'},
  {value: 2026, label: '2026'},
  {value: 2027, label: '2027'}
];

export const STEPS = {
  FINANCE: 'AudiFinance',
  CUSTOMER: 'MyDetails',
  PAYMENT: 'ReserveMyCar',
  THANKYOU: 'ThankYou',
  ENQUIRY: 'Enquiry'
};

export const STRIPE_FIELDS = {
  number: 'cardNumber',
  cvc: 'cardCVC',
  exp_month: 'cardMonth',
  exp_year: 'cardYear'
}

export const RESERVATION_AMT = 500;

// Percentage variance allowed for Desired Driveaway Price input
export const DRIVEAWAY_VARIANCE = 10;

export const FAQ_LINK = "https://www.audi.com.au/en/audi-new-car-search/layer/faq/";

export const EXT_COLOURS = {
  "A2A2": {
    name: "Brilliant black"
  },
  "2Y2Y": {
    name: "Glacier White, metallic",
    thumb: "https://cdn01.audistaging.com.au/cms/media/4361/glacier_white_metallic_2y2y.jpg",
    thumbStg: "https://cdn01.audistaging.com.au/cms/media/4361/glacier_white_metallic_2y2y.jpg"
  },
  "L5L5": {
    name: "Florett Silver, metallic",
    thumb: "https://cdn01.audistaging.com.au/cms/prelaunch/media/1004/florett_silver_l5l5.jpg",
    thumbStg: "https://cdn01.audistaging.com.au/cms/prelaunch/media/1004/florett_silver_l5l5.jpg"
  },
  "2L2L": {
    name: "Typhoon grey, metallic",
    thumb: "https://cdn01.audistaging.com.au/cms/prelaunch/media/1009/typhoon_grey_2l2l.jpg",
    thumbStg: "https://cdn01.audistaging.com.au/cms/prelaunch/media/1009/typhoon_grey_2l2l.jpg"
  },
  "H1H1": {
    name: "Manhattan grey, metallic",
    thumb: "https://cdn01.audistaging.com.au/cms/prelaunch/media/1006/manhattan_grey_h1h1.jpg",
    thumbStg: "https://cdn01.audistaging.com.au/cms/prelaunch/media/1006/manhattan_grey_h1h1.jpg"
  },
  "0E0E": {
    name: "Mythos black, metallic",
    thumb: "https://cdn01.audistaging.com.au/cms/prelaunch/media/1007/mythos_black_0e0e.jpg",
    "thumbStg": "https://cdn01.audistaging.com.au/cms/prelaunch/media/1007/mythos_black_0e0e.jpg"
  },
  "S1S1": {
    name: "Galaxy blue, metallic",
    thumb: "https://cdn01.audistaging.com.au/cms/prelaunch/media/1005/galaxy_blue_s1s1.jpg",
    thumbStg: "https://cdn01.audistaging.com.au/cms/prelaunch/media/1005/galaxy_blue_s1s1.jpg"
  },
  "2D2D": {
    name: "Navarra Blue, metallic",
    thumb: "https://cdn01.audistaging.com.au/cms/prelaunch/media/1008/navarra_blue_2d2d.jpg",
    thumbStg: "https://cdn01.audistaging.com.au/cms/prelaunch/media/1008/navarra_blue_2d2d.jpg"
  },
  "6Y6Y": {
    name: "Daytona grey, pearl effect"
  },
  "3P3P": {
    name: "Antigua blue, metallic"
  },
  "Y6Y6": {
    name: "Catalunya red, metallic"
  }
};

export const MAINTENANCE = {
    reservationUsed: false,
    reservationNew: false
    // enquiry: false,
    // list: false,
};
